import { getFeatureFlagValue } from './helper';
import { IDispatchManagerFeatures } from './interface';

export const dispatchManagerFeatures: IDispatchManagerFeatures = {
    isShowAllSettingsTabs: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_SHOW_ALL_SETTINGS_TABS,
    ),
    isHideIntimetecMailIdUsers: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_IS_HIDE_INTIMETEC_MAIL_ID_USERS,
    ),
    isDM196787ManageTrailer: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_MANAGE_TRAILER_FE,
    ),
    isPrivacyPolicyLink: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_PRIVACY_POLICY_LINK,
    ),
};
